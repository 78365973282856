import React from 'react'
import Layout from 'components/global/layout'
import SubPageHeader from 'components/shared/subPageHeader'
import PageIntro from 'components/global/pageIntro'
import { graphql } from 'gatsby'
import PhotographersWrapper from 'components/photographers/photographersWrapper'
import { StaticImage } from 'gatsby-plugin-image'

const ContactPage = ({ data }) => {
  const photographers = data.photographers.nodes
  return (
    <Layout>
      <SubPageHeader title="CONTACT US">
        <StaticImage src="../images/photographs.png" />
      </SubPageHeader>
    </Layout>
  )
}

export default ContactPage

export const ContactQuery = graphql`
  query {
    photographers: allPhotographersJson {
      nodes {
        name
        img
      }
    }
  }
`
