import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const SubPageHeaderStyles = styled.header`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  section {
    width: 90%;
    margin: var(--auto);
    position: relative;
    z-index: 5;
    text-align: center;
    strong,
    h1 {
      text-transform: uppercase;
    }
    strong {
      display: block;
      font-weight: 600;
      color: var(--alt);
      @media ${media.md} {
        font-size: 1.5rem;
      }
    }
    h1 {
      color: var(--alt);
      margin: 0.25rem 0;
      padding: 0;
      font-size: 2.25rem;
      font-weight: 600;
      @media ${media.mediumMobile} {
        font-size: 2.5rem;
      }
      @media ${media.sm} {
        font-size: 2.85rem;
      }
      @media ${media.md} {
        font-size: 3rem;
      }
      @media ${media.lg} {
        font-size: 3.5rem;
      }
    }
  }

  .hero-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
      z-index: 1;
      content: '';
    }
  }
`
const SubPageHeader = ({ title, text, children }) => {
  const i = true
  return (
    <SubPageHeaderStyles>
      <section>
        <strong>{text}</strong>
        <h1>{title}</h1>
      </section>
      <div className="hero-image">{children}</div>
    </SubPageHeaderStyles>
  )
}

export default SubPageHeader
