import MaxWidth from 'components/global/maxWidth'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const PageIntroStyles = styled.section`
  width: 100%;
  text-align: center;
  padding: 4rem 0 2rem 0;
  background: var(--white);
  h2 {
    font-weight: 300;
    font-size: 1.85rem;
    @media ${media.sm} {
      font-size: 2.25rem;
    }
  }
  p {
    margin: 2rem auto 0 auto;
    max-width: 750px;
  }
  @media ${media.md} {
    padding: 4rem 0 6rem 0;
    h2 {
      font-size: 2.75rem;
    }
    p {
      max-width: 750px;
    }
  }
`
const PageIntro = ({ title, text }) => (
  <MaxWidth>
    <PageIntroStyles>
      <h2>{title}</h2>
      <p>{text}</p>
    </PageIntroStyles>
  </MaxWidth>
)

export default PageIntro
