import GetImage from 'components/functional/getImage'
import MaxWidth from 'components/global/maxWidth'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'
import SinglePhotographer from './singlePhotographers'

const PhotographersWrapperStyles = styled.section`
  width: 100%;
  padding: 1rem 0 3rem 0;
  @media ${media.sm} {
    padding-bottom: 5rem;
  }
  > section {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
    @media ${media.sm} {
      grid-template-columns: repeat(2, 1fr);
    }
    @media ${media.md} {
      grid-template-columns: repeat(3, 1fr);
    }
    @media ${media.lg} {
      grid-template-columns: repeat(4, 1fr);
    }
  }
`

const PhotographersWrapper = ({ data }) => (
  <MaxWidth>
    <PhotographersWrapperStyles>
      <section>
        {data.map((photographer, i) => (
          <SinglePhotographer data={photographer} key={i} />
        ))}
      </section>
    </PhotographersWrapperStyles>
  </MaxWidth>
)

export default PhotographersWrapper
