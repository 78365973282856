import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'

import GetImage from 'components/functional/getImage'
import { media } from 'utils/Media'

const SinglePhotographerStyles = styled.section`
  width: 100%;
  text-align: center;
  .img {
    width: 100%;
    .gatsby-image-wrapper {
      width: 100%;
      max-width: 200px;
      height: auto;
      @media ${media.sm} {
        max-width: 250px;
      }
    }
  }
  strong {
    margin-top: 0.25rem;
    display: block;
    color: var(--main);
    @media ${media.mediumMobile} {
      font-size: 1rem;
    }
  }
  h4 {
    font-weight: 400;
    @media ${media.mediumMobile} {
      font-size: 1.15rem;
    }
    @media ${media.sm} {
      font-size: 1.15rem;
    }
    @media ${media.md} {
      font-size: 1.35rem;
    }
  }
  ${props =>
    props.$slider &&
    css`
      margin: 0 auto;
      text-align: center;
      .img {
        max-width: 150px;
        margin: 0 auto;
        @media ${media.sm} {
          max-width: 180px;
        }
        @media ${media.lg} {
          max-width: 200px;
        }
        @media ${media.xl} {
          max-width: 300px;
        }
      }
    `}
`
const SinglePhotographer = props => {
  const i = true
  return (
    <SinglePhotographerStyles {...props}>
      {/* <div className="img">
        {props.data.img ? (
          <GetImage
            data={props.data.img}
            alt={`${props.data.name} | Generations`}
          />
        ) : (
          <StaticImage
            src="../../images/photographers/placeholder-image.png"
            alt={`${props.data.name} | Generations`}
          />
        )}
      </div> */}
      <h4>{props.data.name}</h4>
      <strong>Photographer</strong>
    </SinglePhotographerStyles>
  )
}
export default SinglePhotographer
